import {
  OwnUpAccordionProps,
  OwnUpFillAccordion,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  ownUpLightTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren, useState } from 'react';
import { getRequiredFields } from '../../modules/handle-sanity-data';
import { OverlineWrapper } from '../overline-text';
import { PortableTextComponent } from '../portable-text';
import {
  FaqItemWrapper,
  FaqListWrapper,
  FAQOverlineText,
  FaqSectionListWrapper,
  HomepageHeadline,
  MoreFAQsButton,
  StyledFaqSection
} from './elements';
import { FaqData, FaqDatum } from './types';

export const FaqItem = ({
  question,
  children,
  ...props
}: PropsWithChildren<Pick<FaqDatum, 'question'> & OwnUpAccordionProps>) => (
  <FaqItemWrapper>
    <OwnUpFillAccordion title={question} {...props}>
      {children}
    </OwnUpFillAccordion>
  </FaqItemWrapper>
);

export const FaqList = ({ faqData, length }: { faqData: FaqData; length?: number }) => {
  const [activeFaq, setActiveFaq] = useState<string | false>(false);
  const handleChange = (faqId: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setActiveFaq(newExpanded ? faqId : false);
  };
  const faqs = faqData
    .slice(0, length ? length : faqData.length)
    .map(({ question, answer }, idx) => {
      const faqId = String(idx);
      return (
        <FaqItem
          key={faqId}
          question={question}
          expanded={faqId === activeFaq}
          onChange={handleChange(faqId)}
        >
          {answer?.body && <PortableTextComponent blocks={answer?.body} />}
        </FaqItem>
      );
    });

  return <FaqListWrapper>{faqs}</FaqListWrapper>;
};

export const FaqWrapper = ({
  children,
  isHomepage
}: PropsWithChildren<{ isHomepage?: boolean }>) => (
  <>{isHomepage ? <>{children}</> : <OwnUpGridWrapper>{children}</OwnUpGridWrapper>}</>
);

export const FaqSection = ({
  faqData,
  moreFaqsUrl,
  title
}: {
  faqData: Record<string, any>;
  moreFaqsUrl?: string;
  title?: string;
}) => {
  const types: string[] = ['heading', 'faqs'];
  const content = faqData ? getRequiredFields(faqData, types) : {};

  const hasCustomTitle = title !== undefined;

  return (
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <StyledFaqSection aria-labelledby="faqSectionHeading" homePageColor={hasCustomTitle}>
        <OverlineWrapper>
          {hasCustomTitle ? (
            <HomepageHeadline>{title}</HomepageHeadline>
          ) : (
            <FAQOverlineText id="faqSectionHeading">
              {content?.headingObj?.heading || 'FAQS'}
            </FAQOverlineText>
          )}
        </OverlineWrapper>
        <FaqWrapper isHomepage={hasCustomTitle}>
          <OwnUpGridContainer variant="legacy">
            <OwnUpGridOffset xs={0} lg />
            <OwnUpGridItem xs={12} lg={10} xl={8}>
              <FaqSectionListWrapper>
                <FaqList
                  faqData={content?.faqsObj?.pageBuilder || []}
                  length={hasCustomTitle ? 5 : undefined}
                />
              </FaqSectionListWrapper>
              {moreFaqsUrl ? <MoreFAQsButton href={moreFaqsUrl} /> : null}
            </OwnUpGridItem>
            <OwnUpGridOffset xs={0} lg />
          </OwnUpGridContainer>
        </FaqWrapper>
      </StyledFaqSection>
    </OwnUpThemeProvider>
  );
};
